:root {
  font-family: 'Poppins', Helvetica, Arial, sans-serif;
  line-height: 1.5;
  font-weight: 400;
  font-size: 100%;

  color-scheme: light dark;
  color: var(--main-color);

  --main-color: #202020;
  --black-color: #121212;
  --white-color: #ffffff;
  --bright-color-100: #f3f3f3;
  --bright-color: #e7e9ec;
  --gray-color-light: #c7c7c7;
  --gray-color: #9d9d9d;
  --gray-color-dark: #515151;
  --ff-primary: 'Poppins', sans-serif;
  --fw-medium: 500;
  --fw-semi-bold: 600;
  --fw-bold: 700;
  --fs-300: ;
  --fs-400: 0.9rem;
  --fs-500: 1rem;
  --fs-600: 1.1rem;
  --fs-700: 1.2rem;
  --fs-800: 1.3rem;
  --fs-900: 1.9rem;
  --fs-xl: 10rem;

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  display: flex;
  place-items: center;
  min-width: 320px;
  min-height: 100vh;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  background-color: var(--bright-color);
  transition: background-color 0.2s ease-out;
}

.container_page {
  max-width: 65.5rem;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 5.4rem auto 0 auto;
  padding: 20px 20px 40px 20px;
  border: 17px solid var(--main-color);
  border-radius: 10px 10px 0 0;
}

h2.title {
  text-align: center;
  width: 100%;
  margin-block: 0;
}

a,
a:hover,
a:visited,
a:link,
label {
  color: var(--main-color);
  text-decoration: none;
  transition: color 0.3s ease;
}

:focus {
  outline: 1px solid var(--gray-color);
}

p {
  text-wrap: pretty;
}

h1 {
  font-size: 3.2em;
  line-height: 1.1;
  text-wrap: balance;
}

/* button {
  border-radius: 8px;
  border: 1px solid transparent;
  padding: 0.6em 1.2em;
  font-size: 1em;
  font-weight: 500;
  font-family: inherit;
  background-color: #1a1a1a;
  cursor: pointer;
  transition: border-color 0.25s;
}

button:hover {
  border-color: var(--gray-color);
} 

button:focus,
button:focus-visible {
  outline: 4px auto -webkit-focus-ring-color;
}
*/

@media (prefers-color-scheme: dark) {
  body,
  div {
    background-color: var(--main-color);
    color: var(--gray-color-light);
  }

  img {
    filter: brightness(0.95);
  }

  a,
  a:hover,
  a:visited,
  a:link {
    color: var(--gray-color);
  }

  label {
    color: var(--gray-color-light);
  }

  button {
    background-color: var(--gray-color);
  }

  body .cover {
    box-shadow: none !important;
  }
}

.sl-overlay {
  opacity: 1 !important;
}

/* **************** dark mode **************** */

body.dark-mode,
body.dark-mode div {
  background-color: var(--main-color);
  color: var(--gray-color-light);
  transition: background-color 0.2s ease-out;
}

body.dark-mode img {
  filter: brightness(0.95);
  box-shadow: none !important;
}

body.dark-mode .cover {
  box-shadow: none !important;
}

body.dark-mode select,
body.dark-mode input {
  background-color: #3d3d3d;
  color: #a8a8a8;
}

body.dark-mode ul a {
  color: var(--white-color) !important;
}

body.dark-mode label {
  color: var(--gray-color-light);
}

body.dark-mode header a,
body.dark-mode span {
  color: var(--gray-color-light) !important;
}

body.dark-mode a,
body.dark-mode a:visited,
body.dark-mode a:link,
body.dark-mode label {
  color: var(--gray-color);
}

.select__input-container {
  background-color: transparent !important;
}

body.dark-mode a:hover {
  color: var(--white-color) !important;
}

body.dark-mode .sl-close,
body.dark-mode .sl-next,
body.dark-mode .sl-prev,
body.dark-mode .sl-counter {
  color: var(--gray-color) !important;
}

body.dark-mode .container_page {
  border: 7px solid var(--main-color);
  background-color: var(--main-color);
}

body.dark-mode .nav_page_left {
  border-right: 30px solid var(--gray-color) !important;
}

body.dark-mode .customize {
  color: var(--gray-color-light);
}

body.dark-mode .sort_input,
body.dark-mode .filters_input,
body.dark-mode .search_main_cover_input {
  background-color: #343434;
  color: #b7b7b7;
}

body.dark-mode .breadcrumbs {
  font-weight: 500;
  color: #d7d7d7;
}

body.dark-mode a.hover {
  color: var(--white-color);
}

/* **************** SMALL DEVICES **************** */

@media (max-width: 55rem) {
  /* 880px */
}

@media (max-width: 40rem) {
  /* 640px */
}

@media (max-width: 45.625rem) {
  /* 730px */
}

@media (max-width: 33.313rem) {
  /* 533px */

  .container_page {
    padding: 1rem 0 2rem 0;
    border: 7px solid var(--main-color);
    border-radius: 0;
    margin-top: 4.3rem;
    gap: 1rem;
  }

  h2.title {
    margin-bottom: 0.75rem;
  }
}

@media (max-width: 25rem) {
  /* 400px */
}
