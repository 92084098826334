header {
  position: absolute;
  width: 100%;
  height: 5.2rem;
  background-color: var(--main-color);
  display: flex;
  align-items: center;
  z-index: 90;
}

.navbar {
  width: min(1048px, 95%);
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 15% auto 15%;
  align-content: center;
  margin-inline: auto;
}

.navbar > * {
  align-self: center;
}

.navbar_links {
  display: flex;
  justify-content: center;
  align-self: center;
}

.navbar_links li {
  list-style: none;
  display: block;
  line-height: 2.5em;
}

.navbar_links a {
  padding: 0 1.2rem;
  height: 40px;
  color: var(--gray-color);
  font-size: 1.1rem;
  font-weight: 500;
  text-transform: uppercase;
  text-decoration: none;
  display: block;
  align-items: center;
  align-content: center;
  border-right: 1px solid var(--gray-color-dark);
}

.navbar_links a:visited {
  color: var(--gray-color);
}

.navbar_links a:hover,
.navbar_links a:active {
  color: var(--white-color);
}

.navbar_links a.active {
  color: var(--white-color);
}

.toggle_button {
  display: none;
}

.facebook {
  place-self: center;
  margin-top: 4px;
}

.facebook a {
  border-right: 0;
}

.additions {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.additions::before {
  content: '';
  position: relative;
  left: 3.7rem;
  height: 40px;
  width: 1.2rem;
  border-left: 1px solid var(--gray-color-dark);
}

.search_panel {
  position: absolute;
  overflow-y: auto;
  margin-top: 5.2rem;
  margin-bottom: auto;
  inset: 0;
  width: 100%;
  transition: transform 0.2s ease-in-out;
  transform: translateY(-100%);
  z-index: 2;
}

.search_panel.visible {
  transition: transform 0.2s ease-in-out;
  transform: translateY(0);
}

.search_panel.hidden {
  transform: translateY(-100%);
}

.search_panel_container {
  background-color: var(--main-color);
  padding-bottom: 0.5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.search_input {
  min-height: 2rem;
  width: min(400px, 80%);
  border: 0;
}

.icon_container {
  display: flex;
  gap: 1.5rem;
}

.search_phrase {
  color: var(--bright-color-100);
}

.search_output {
  min-height: 1rem;
  width: min(800px, 90%);
  text-align: center;
  font-weight: 200;
}

.search_output_single {
  line-height: 1.5em;
  overflow: hidden;
  margin-block: 2rem;
  padding-block: 1rem;
  cursor: pointer;
  border: 1px solid gray;
}

.search_output_single:hover {
  background-color: var(--gray-color-dark);
}

.search_output a {
  color: var(--bright-color);
  font-weight: 600;
}

.result_element {
  border-bottom: solid 1px var(--gray-color);
}

.toggle_button_theme {
  margin-left: 0.4rem;
  cursor: pointer;
  padding-inline: 0.9rem;
}

.logo {
  transform: scale(0.85);
  margin-left: 15px;
}

.icon {
  margin-top: 1rem;
  width: 24px;
  cursor: pointer;
}

.icon:hover,
.icon_search:hover,
.toggle_button:hover,
.toggle_button_theme:hover {
  filter: brightness(1.3);
}

.icon_zoom_in {
  margin-top: -0.5rem;
}

.icon_zoom_in:hover {
  filter: none;
  cursor: none;
}

.icon_search {
  width: 22px;
  cursor: pointer;
  margin-right: 1rem;
}

.facebook .icon {
  margin-top: 0.25rem;
  margin-left: 0.35rem;
}

[aria-current]:not([aria-current='false']) {
  color: var(--white-color);
}

/* @MEDIA */

@media (max-width: 55rem) {
  /* 880px */

  .navbar {
    grid-template-columns: auto auto;
  }

  .logo {
    transform: scale(0.7);
  }

  .search_panel_container {
    padding-top: 0.75rem;
  }

  .navbar_links {
    display: none;
    width: 100%;
  }

  .navbar_links.visible {
    position: absolute;
    top: 4rem;
    left: 0;
    display: grid;
    grid-auto-flow: row;
    row-gap: 1rem;
    padding-inline-start: 0;
    padding-block-start: 0.75rem;
    padding-block-end: 1rem;
    background-color: var(--main-color);
    z-index: 99;
    animation: ease-down-2 0.15s ease-out;
  }

  @keyframes ease-down-2 {
    from {
      height: 0;
    }
    to {
      height: 14rem;
    }
  }

  .navbar_links li {
    text-align: center;
  }

  .navbar_links a {
    border: 0;
  }

  .additions {
    position: absolute;
    right: 2rem;
  }

  .toggle_button {
    position: absolute;
    right: calc(50% - 30px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 19px;
    padding: 20px;
    border: 1px solid var(--gray-color-dark);
    border-radius: 1rem;
  }

  .toggle_button .bar {
    display: block;
    width: 25px;
    height: 3px;
    background-color: var(--gray-color);
    transition: transform 0.4s ease;
  }

  .toggle_button.active .bar:nth-child(1) {
    transform: rotate(-45deg) translate(-5px, 6px);
  }

  .toggle_button.active .bar:nth-child(2) {
    opacity: 0;
  }

  .toggle_button.active .bar:nth-child(3) {
    transform: rotate(45deg) translate(-5px, -6px);
  }

  .description {
    padding-block-start: 1.25rem;
    padding-inline: 0.5rem;
  }
}

@media (max-width: 33.313rem) {
  /* 533px */

  header {
    height: 4.3rem;
  }

  .navbar {
    margin-inline: -2rem;
  }

  .logo {
    transform: scale(0.5);
  }

  .additions {
    top: 1rem;
    right: 0.5rem;
  }

  .toggle_button {
    top: 0.5rem;
    right: calc(50% - 28px);
    padding: 16px;
  }

  .navbar_links.visible {
    top: 3.2rem;
  }

  .navbar_links.active {
    top: 3.3rem;
  }

  .search_panel {
    margin-top: 4.3rem;
  }

}
