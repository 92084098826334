.container {
  width: min(65.5rem, 95%);
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15.3rem, 1fr));
  grid-column-gap: 1.1rem;
  grid-row-gap: 3.1rem;
  justify-items: center;
  margin-inline: auto;
  margin-bottom: 4.5rem;
}

.cover {
  width: 237px;
  height: 327px;
  cursor: pointer;
  filter: brightness(1);
  outline: 0;
}

.cover img {
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.4);
}

.cover:hover {
  outline: 2px solid #dcdcdc;
}

.cover_picture {
  display: block;
  left: auto;
  right: auto;
  position: absolute;
  top: 0;
  margin: auto;
  filter: brightness(1);
}

.title_under_cover {
  width: 100%;
  height: 30px;
  line-height: 1.2rem;
  display: block;
  left: auto;
  right: auto;
  position: absolute;
  bottom: 0;
  margin: auto;
  margin-bottom: -38px;
  text-align: center;
  font-weight: 500;
  /* white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis; */
}

.new::after,
.sold::after,
.last_items::after,
.preview::after {
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--bright-color);
  display: block;
  position: relative;
  left: -0.62rem;
  border-radius: 0 10px 10px 0;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.4);
}

.new::after {
  content: 'NOWOŚĆ';
  width: 6rem;
  top: 0;
  background-color: #2d7a85;
  padding: 10px 15px 7px 15px;
}

.sold::after {
  content: 'WYPRZEDANE';
  width: 8.5rem;
  top: 65%;
  background-color: #303030;
  padding: 10px 15px 10px 15px;
}

.last_items::after {
  content: 'OSTATNIE SZTUKI';
  width: 11rem;
  top: 65%;
  background-color: #9b3838;
  padding: 10px 15px 10px 15px;
}

.preview::after {
  content: 'ZAPOWIEDŹ';
  width: 7.5rem;
  top: 0;
  background-color: #d36526;
  padding: 10px 15px 7px 15px;
}

.new::before {
  content: '';
  top: 2.05rem;
  left: -2.5rem;
  padding: 10px 15px;
  position: relative;
  border-top: 10px solid transparent;
  border-right: 10px solid #235a62;
}

.sold::before,
.last_items::before {
  content: '';
  top: 15.4rem;
  left: -2.5rem;
  padding: 10px 15px;
  position: relative;
  border-top: 10px solid transparent;
  border-right: 10px solid #235a62;
}

.sold::before {
  border-right-color: #1b1b1b;
}

.last_items::before {
  border-right-color: #7e2828;
}

.preview::before {
  content: '';
  top: 2.05rem;
  left: -2.5rem;
  padding: 10px 15px;
  position: relative;
  border-top: 10px solid transparent;
  border-right: 10px solid #be5315;
}

@media (max-width: 33.313rem) {
  /* 533px */

  .cover {
    width: 290px;
    height: 400px;
  }

  .sold::before,
  .last_items::before {
    content: '';
    top: 18.4rem;
  }
}
