.container {
  display: flex;
  max-width: 74.5rem;
  margin-inline: auto;
  gap: 0.25rem;
}

.blank_for_mobile_touch {
  display: none;
}

.nav_page_container {
  width: 30px;
  z-index: 20;
}

.nav_page {
  align-self: flex-start;
  color: var(--bright-color-100);
  cursor: pointer;
  margin-top: 9.2rem;
  width: 0;
  height: 0;
  border-top: 30px solid transparent;
  border-bottom: 30px solid transparent;
}

.nav_page_left {
  border-right: 30px solid var(--gray-color);
  transition: 0.3s ease-in-out;
}

.nav_page_left:hover {
  border-right: 30px solid var(--gray-color-light);
}

.nav_page_right {
  border-left: 30px solid var(--gray-color);
  transition: 0.3s ease-in-out;
}

.nav_page_right:hover {
  border-left: 30px solid var(--gray-color-light);
}

.container_covers {
  order: 1;
  min-height: 800px;
  height: auto;
}

.container_info {
  order: 2;
  width: auto;
  max-width: 475px;
  display: grid;
  align-content: flex-start;
  justify-items: center;
  gap: 15px;
}

.description {
  order: 3;
  margin-bottom: 15px;
  line-height: 1.6rem;
  padding-inline: 3.75rem;
  padding-block-start: 1rem;
}

.video_container {
  order: 4;
}

.video {
  max-width: 560px;
  max-height: 272px;
  width: 100%;
  height: 100%;
}

/* SECTION PANELS */
.inside_panels {
  order: 5;
  display: grid;
  justify-items: center;
  place-self: center;
  width: 100%;
}

.headline_media {
  display: block;
  text-align: center;
  font-size: 1.3rem;
  font-weight: 700;
  margin: 10px auto 20px auto;
}

.review_panel {
  order: 6;
  display: grid;
  justify-items: center;
  place-self: center;
  margin-top: 1.5rem;
  line-height: 0.6rem;
  width: 90%;
}

.img_inside {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
}

h1.title {
  text-align: center;
  font-size: 1.9rem;
  line-height: 2.6rem;
  margin-block-start: 0.8rem;
  margin-block-end: 0.4rem;
}

.cover_big {
  width: 433px;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.5);
}

.cover_big_set {
  width: 442px;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.5);
}

.choose_cover {
  margin-top: 0.8rem;
  margin-bottom: 0.4rem;
  font-size: 0.9rem;
  font-weight: 700;
  color: #808080;
}

.covers_thumbnails {
  display: flex;
  align-content: flex-start;
  gap: 1.2rem;
  height: auto;
  flex-wrap: wrap;
  max-width: 430px;
  width: auto;
}

.covers_thumbnails_1of4 {
  display: flex;
  align-content: flex-start;
  gap: 1.2rem;
  height: auto;
  flex-wrap: wrap;
  width: auto;
}

.covers_thumbnails_set {
  max-width: 280px;
}

.cover_regular_thumbnail,
.cover_alternative1_thumbnail,
.cover_alternative2_thumbnail,
.cover_alternative1of4_thumbnail,
.cover_alternative2of4_thumbnail,
.cover_alternative3of4_thumbnail,
.cover_alternative4of4_thumbnail,
.cover_regulartom1_thumbnail,
.cover_regulartom2_thumbnail,
.cover_alternative1tom1_thumbnail,
.cover_alternative1tom2_thumbnail,
.cover_alternative2tom1_thumbnail,
.cover_alternative2tom2_thumbnail {
  width: 102px;
  height: 141px;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  display: none;
}

.cover_alternative1_thumbnail::after,
.cover_alternative2_thumbnail::after,
.cover_alternative1of4_thumbnail::after,
.cover_alternative2of4_thumbnail::after,
.cover_alternative3of4_thumbnail::after,
.cover_alternative4of4_thumbnail::after,
.cover_regulartom1_thumbnail::after,
.cover_regulartom2_thumbnail::after,
.cover_alternative1tom1_thumbnail::after,
.cover_alternative1tom2_thumbnail::after,
.cover_alternative2tom1_thumbnail::after,
.cover_alternative2tom2_thumbnail::after {
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--bright-color);
  width: 1rem;
  position: relative;
  top: 6px;
  left: 0;
  padding: 0.1rem 0.4rem;
  border-radius: 0 5px 5px 0;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.4);
  background-color: #2d7a85;
}

.cover_alternative1_thumbnail::after {
  content: 'A';
}

.cover_alternative2_thumbnail::after {
  content: 'B';
}

.cover_alternative1of4_thumbnail::after {
  content: 'A';
}
.cover_alternative2of4_thumbnail::after {
  content: 'B';
}
.cover_alternative3of4_thumbnail::after {
  content: 'C';
}
.cover_alternative4of4_thumbnail::after {
  content: 'D';
}

.cover_regulartom1_thumbnail::after {
  content: 'tom I';
}
.cover_regulartom2_thumbnail::after {
  content: 'tom II';
}

.cover_alternative1tom1_thumbnail::after {
  content: 'A - tom I';
}

.cover_alternative1tom2_thumbnail::after {
  content: 'A - tom II';
}

.cover_alternative2tom1_thumbnail::after {
  content: 'B - tom I';
}

.cover_alternative2tom2_thumbnail::after {
  content: 'B - tom II';
}

.comics_data {
  display: grid;
  grid-template-columns: auto auto;
  list-style: none;
  padding-inline-start: 0;
  line-height: 1.6rem;
}

.comics_data > :nth-child(odd) {
  text-align: right;
  padding-right: 10px;
  border-right: 2px solid #797979;
}

.comics_data > :nth-child(even) {
  text-align: left;
  padding-left: 10px;
  font-weight: 600;
}

.main_info {
  row-gap: 8px;
}

.main_info > :nth-child(even) {
  font-weight: 600;
}

.details {
  /*  background-color: var(--bright-color); */
  border-radius: 10px;
  padding: 10px 20px 10px 20px;
  margin-block-end: 0;
}

.details > :nth-child(even) {
  font-weight: 500;
}

.details_original_edition {
  row-gap: 4px;
}

.details_original_edition > :nth-child(even) {
  font-weight: 500;
}

.role {
  text-align: right;
  padding-right: 13px;
  position: relative;
}

.role::before {
  content: '';
  height: 90%;
  position: absolute;
  top: 12px;
  bottom: 12px;
  right: 0;
  border-right: 2px solid #797979;
}

.name {
  text-align: left;
  padding-left: 13px;
  font-weight: 500;
}

.comics_data_headline {
  line-height: 0;
  font-size: 1.1rem;
  font-weight: 500;
  color: #808080;
  margin-block-start: 0.5rem;
  margin-block-end: -10px;
}

.buy_element {
  justify-self: center;
}

.buy {
  width: 14.5rem;
  height: 4.3rem;
  background-color: #19a538;
  border-radius: 10px;
  border: 0;
  font-family: inherit;
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 1.6rem;
  color: var(--white-color);
  cursor: pointer;
  margin-bottom: 10px;
  transition: background-color 0.25s;
}

.buy:hover {
  background-color: #15862e;
}

.buy:focus,
.buy:focus-visible {
  outline: 0.1rem solid var(--white-color);
}

.buy::before {
  position: relative;
  top: 3px;
  right: 12px;
}

.buy:hover ~ .buy_links::before {
  background-color: #15862e;
}

.buy_links {
  display: none;
  margin-top: -10px;
  width: 14.5rem;
  height: auto;
  list-style: none;
  padding-inline-start: 0;
  font-size: 1rem;
  justify-self: center;
  cursor: pointer;
  text-align: center;
  position: absolute;
  z-index: 99;
}

.buy_links::before {
  display: block;
  content: '';
  margin-top: -10px;
  background-color: #19a538;
  width: 14.5rem;
  height: 0.6rem;
  position: relative;
  transition: background-color 0.25s;
}

.buy_links_on {
  display: grid;
}

.buy_link {
  height: 45px;
  line-height: 45px;
  background-color: #2ac34c;
  border-top: solid 1px var(--white-color);
}

.buy_link:hover {
  background-color: #19a538;
}

.buy_link a {
  color: var(--white-color);
}

.buy_link:last-of-type {
  border-radius: 0 0 10px 10px;
}

.related_comics_section {
  order: 7;
  margin-top: 5rem;
  width: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  font-size: 0.9rem;
  font-weight: 700;
  color: #808080;
}

.related_comics_section::before {
  content: 'Powiązane tytuły:';
  position: absolute;
  margin-top: -2rem;
  line-height: 0;
  font-size: 1.1rem;
  font-weight: 500;
  color: #808080;
}

.related_comics {
  height: 225px;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

.related_comics:hover {
  outline: 2px solid #dcdcdc;
}

.inside_thumbnails {
  height: 290px;
  cursor: pointer;
  position: relative;
}

.inside_thumbnails:hover {
  /*   outline: 2px solid #dcdcdc;
  filter: saturate(30%); */
}

.inside_thumbnails:hover::after {
  content: ''; /* Dodanie treści do pseudoelementu */
  position: absolute; /* Ustawienie pozycji absolutnej */
  top: 50%; /* Wyśrodkowanie pionowe */
  left: 50%; /* Wyśrodkowanie poziome */
  transform: translate(-50%, -50%); /* Przesunięcie na środek obrazka */
  width: 40px; /* Szerokość lupy */
  height: 40px; /* Wysokość lupy */
  background-image: url('icon-zoom-in.svg'); /* Dodanie obrazka lupy */
  background-size: cover; /* Dopasowanie obrazka */
}

.current_page {
  color: var(--white-color) !important;
}

.icon {
  width: 24px;
  cursor: pointer;
}

.icon:hover,
.toggle_mode:hover {
  filter: brightness(1.5);
}

.icon_zoom_in {
  margin-top: -0.5rem;
}

.icon_zoom_in:hover {
  filter: none;
  cursor: none;
}

@media (max-width: 55rem) {
  /* 880px */

  .description {
    padding-inline: 0.5rem;
  }
}

@media (max-width: 33.313rem) {
  /* 533px */

  .nav_page_container {
    display: none;
  }

  .cover {
    width: 290px;
    height: 400px;
  }

  .container_covers {
    width: 95%;
    height: auto;
  }

  .cover_big,
  .inside_thumbnails {
    width: 100%;
    height: auto;
  }

  .breadcrumbs {
    grid-template-rows: auto auto;
    justify-content: center;
    margin-top: 1rem;
    gap: 0.5rem;
  }

  .container_page {
    margin-top: 56px;
    padding: 14px;
  }

  .related_comics_section {
    grid-auto-flow: row;
  }

  .related_comics_section::before {
    width: 10rem;
  }

  .description {
    padding-block-start: 0;
    padding-inline: 0.5rem;
    margin-bottom: 0;
  }
}

@media (max-width: 25rem) {
  /* 400px */
}
