.breadcrumbs {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: start;
  gap: 1.3rem;
  max-width: 62rem;
  height: 3rem;
  line-height: 3rem;
  margin: 2rem auto 0 auto;
  position: relative;
}

.breadcrumbs > * {
  background-color: var(--bright-color-100);
  border-radius: 0.7rem 0.7rem 0 0;
  padding: 0 1.6rem;
  text-align: center;
}

.breadcrumbs_element {
  color: var(--main-color);
}

.breadcrumbs .title {
  font-weight: 600;
  color: var(--main-color);
}

.back_link_top {
  cursor: pointer;
}

.comics_preview {
  display: flex;
  width: 60rem;
  column-gap: 2rem;
  margin-top: 1.2rem;
  border: 4px solid #dcdcdc;
  border-radius: 0.75rem;
  padding: 1rem;
  background-color: var(--bright-color-100);
  justify-content: space-evenly;
}

.comics_preview_img img {
  width: 300px;
}

.comics_preview_info {
  width: 30rem;
}

.comics_data {
  display: grid;
  grid-template-columns: auto auto;
  list-style: none;
  padding-inline-start: 0;
  line-height: 1.6rem;
}

.comics_data > :nth-child(odd) {
  text-align: right;
  padding-right: 10px;
  border-right: 2px solid #797979;
}

.comics_data > :nth-child(even) {
  text-align: left;
  padding-left: 10px;
  font-weight: 600;
}

.main_info {
  row-gap: 8px;
  margin-bottom: 2rem;
}

.main_info > :nth-child(even) {
  font-weight: 600;
}

h1.title {
  text-align: center;
  font-size: 1.9rem;
  line-height: 2.6rem;
  margin-block-start: 0.8rem;
  margin-block-end: 0.4rem;
}

@media (max-width: 58.125rem) {
  /* 930px */
  .comics_preview_info {
    width: 20rem;
  }
}

@media (max-width: 45.625rem) {
  /* 730px */

  .comics_preview {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .comics_preview_img {
    display: flex;
    justify-content: center;
  }

  .comics_preview_img img {
    max-width: 90%;
    width: 300px;
  }
}

@media (max-width: 33.313rem) {
  /* 533px */
 
  .comics_preview {
    display: flex;
    width: auto;
    column-gap: 2rem;
    margin-top: 1.2rem;
    border: 4px solid #dcdcdc;
    padding: 0;
    background-color: var(--bright-color-100);
  }

  .comics_preview_img img {
    width: auto;
  }

  .comics_preview {
    display: flex;
    width: 100vw;
    padding-inline: 0.5rem;
    margin-top: 0;
  }

  .text_introduce {
    padding-inline: 0.75rem;
  }
}
