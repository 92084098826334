.customize {
  width: min(64rem, 92%);
  margin-block-start: 6.5rem;
  margin-block-end: 1.5rem;
  display: grid;
  grid-template-columns: 14rem auto 14rem;
  justify-content: stretch;
  align-content: center;
  margin-inline: auto;
  font-weight: var(--fw-medium);
  column-gap: 1rem;
}

/* .sort,
.filters,
.search_main_cover {
  display: flex;
  grid-template-columns: auto;
  column-gap: 0.7rem;
  line-height: 2.2rem;
} */

/* text elements to the left of input or select */
.sort > :nth-child(odd),
.filters > :nth-child(odd),
.search_main_cover > :nth-child(odd) {
  text-align: center;
}

.sort_input,
.filters_input,
.search_main_cover_input {
  width: 100%;
  min-height: 38px;
  font-size: 1.1rem;
  background-color: hsl(0, 0%, 100%);
  border: 0;
  border-radius: 5px;
  cursor: pointer;
  box-sizing: border-box;
}

.sort_input,
.filters_input {
  padding-left: 0.6rem;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.35em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
}

.sort_input:focus,
.filters_input:focus {
  background-image: linear-gradient(45deg, gray 50%, transparent 50%),
    linear-gradient(135deg, transparent 50%, gray 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position: calc(100% - 15px) 1em, calc(100% - 20px) 1em,
    calc(100% - 2.5em) 0.35em;
}

@media (max-width: 55rem) {
  .customize {
    grid-template-columns: 14rem auto;
    row-gap: 0.5rem;
  }

  .customize :nth-child(2) {
    order: 3;
    grid-column: span 2;
  }

  .search_main_cover {
    order: 1;
  }
}

@media (max-width: 33.313rem) {
  .customize {
    margin-block-start: 5.3rem;
    grid-template-columns: 1fr 1fr;
  }
}
