:global(.container_page) {
  align-items: flex-start;
}

.contact {
  border: 2px solid var(--gray-color-light);
  border-radius: 1rem;
  padding-inline: 3rem;
  text-align: center;
  background-color: var(--bright-color-100);
  margin-top: 1rem;
}

.contact p {
  line-height: 1rem;
}

.shop {
  margin-block-start: 2.5rem;
}

@media (max-width: 33.313rem) {
  /* 533px */
  .contact {
    width: 90%;
    padding-inline: 0.5rem;
  }

  p.text_warning {
    padding-inline: 1rem;
  }
}
