.bio {
  display: flex;
  gap: 2rem;
}

.bio_item {
  border: 2px solid var(--gray-color-light);
  border-radius: 1rem;
  padding-inline: 3rem;
  text-align: center;
  background-color: var(--bright-color-100);
  margin-top: 1rem;
}

.image_logo {
  width: 200px;
}

@media (max-width: 33.313rem) {
  /* 533px */
  .bio {
    flex-direction: column;
    align-items: center;
  }

  .bio p {
    padding-inline: 1rem;
    margin-block: 0;
  }

  .bio_item {
    width: 90%;
    padding-inline: 0.5rem;
  }
}
